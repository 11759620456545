import { forwardRef } from 'react';

import type { WProductView } from '@zola/svc-web-api-ts-client';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { PencilIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Pencil';

import { setEditZolaGiftCard } from '~/actions/ManageRegistryActions';
import defaultGiftCardImg from '~/assets/images/ManageRegistry/gift-card.jpg';
import { useAppDispatch } from '~/store';

import {
  BrandName,
  GiftCardText,
  ItemName,
  TileActions,
  TileBody,
  TileContainer,
} from './RegistryTile.styles';
import {
  TileImageContainer,
  TileImage,
  TileEditActions,
  IconButton,
} from './RegistryTileImage.styles';
import { BottomSection, PriceContainer, Price } from './RegistryTilePrice.styles';

interface RegistryTileProps {
  hideMobileEdit?: boolean;
  editable?: boolean;
  onItemClick?: (item: WProductView, qty?: number) => void;
  zolaGiftCard: WProductView | null;
  isZolaGiftCardEnabled?: boolean;
}

export const RegistryZolaGiftCard = forwardRef<HTMLDivElement, RegistryTileProps>(
  ({ hideMobileEdit, editable = false, onItemClick, zolaGiftCard, isZolaGiftCardEnabled }, ref) => {
    const dispatch = useAppDispatch();

    const handleItemClick = () => {
      if (!zolaGiftCard) return;

      if (editable) {
        dispatch(setEditZolaGiftCard(true));
      } else if (onItemClick) {
        onItemClick(zolaGiftCard);
      }
    };

    if (!editable && !zolaGiftCard) return null;

    const tileImage =
      zolaGiftCard?.product_look_views?.[0].images?.[0].aspect_ratios?.['1x1'].medium ||
      defaultGiftCardImg;
    const giftEditText = isZolaGiftCardEnabled ? 'Edit' : 'Add';
    const ctaText = editable ? giftEditText : 'Choose amount';

    return (
      <TileContainer ref={ref} isActive={editable} onClick={handleItemClick}>
        <TileImageContainer>
          <TileImage src={tileImage} alt={zolaGiftCard?.name || 'Zola Gift Card'} />
          {!hideMobileEdit && (
            <TileEditActions>
              <IconButton mobileOnly variant="secondary" onClick={handleItemClick}>
                <PencilIcon height={20} width={20} />
              </IconButton>
            </TileEditActions>
          )}
        </TileImageContainer>
        <TileBody>
          <BrandName>Zola</BrandName>
          <ItemName>{zolaGiftCard?.name || 'Gift Card'}</ItemName>
          <PriceContainer>
            <Price>Starts at $25</Price>
          </PriceContainer>
          <BottomSection>
            {editable && <GiftCardText>One gift card, hundreds of top brands.</GiftCardText>}
            <TileActions showAction={!editable}>
              <ButtonV3 onClick={handleItemClick}>{ctaText}</ButtonV3>
            </TileActions>
          </BottomSection>
        </TileBody>
      </TileContainer>
    );
  }
);

RegistryZolaGiftCard.displayName = 'RegistryZolaGiftCard';
