import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

import styled from '@emotion/styled';

import { ContributionContainer } from '~/components/common/RegistryTiles/RegistryTilePrice.styles';
import { DiscountText } from '~/components/common/SimpleTile/SimpleTilePriceContent.styles';

export const TileActions = styled.div<{ showAction?: boolean }>`
  display: ${(props) => (props.showAction ? 'flex' : 'none')};
  column-gap: 12px;
  > button {
    flex: 1 1 auto;
  }
`;

export const ItemName = styled.div`
  font-weight: ${FONT.FONT_WEIGHT_BOLD};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
`;

// Matching less variable: @registry-tiles-three-tile-breakpoint
export const THREE_TILE_BREAKPOINT = `586px`;

export const GiftCardText = styled.div``;

export const TileContainer = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  min-width: 0px;
  min-height: 340px;
  border-bottom: var(--border-grid, 1px solid ${COLORS3.BLACK_030});
  border-right: var(--border-grid, 1px solid ${COLORS3.BLACK_030});
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 8px;

  font-size: ${FONT.FONT_SIZE_SMALLER};
  color: ${COLORS3.BLACK_100};

  strong {
    font-weight: ${FONT.FONT_WEIGHT_BOLD};
  }

  @container registry-tiles (min-width: ${THREE_TILE_BREAKPOINT}) {
    padding: 0;
    border: none;
    border-radius: 16px; // Match TileImage
    font-size: ${FONT.FONT_SIZE_SMALL};
    min-height: 495px;

    &:hover {
      ${({ isActive }) =>
        isActive &&
        `
      ${ContributionContainer}, ${GiftCardText} {
        display: none;
      }
      ${TileActions} {
        display: flex;
        margin-top: 16px;
      }
      ${DiscountText} {
        visibility: hidden;
      }
    `}
    }
  }
`;

export const TileBody = styled.div`
  background: var(--registry-tile-background);
  padding-left: var(--registry-tile-body-side-padding-mobile, 0);
  padding-right: var(--registry-tile-body-side-padding-mobile, 0);
  padding-top: 16px;
  padding-bottom: var(--registry-tile-body-bottom-padding-mobile, 0);
  border-radius: var(--registry-tile-border-radius-mobile);

  @container registry-tiles (min-width: ${THREE_TILE_BREAKPOINT}) {
    padding-bottom: 16px;
    padding-left: var(--registry-tile-body-side-padding, 0);
    padding-right: var(--registry-tile-body-side-padding, 0);
    border-radius: none;
  }
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const BrandName = styled.div`
  height: 23px;
  color: ${COLORS3.BLACK_050};
`;
